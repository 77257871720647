import firebaseService from "./firebase-service";

let analytics = null;

const instance = {
    init: () => {
        analytics = firebaseService.getAnalytics();
    },
    logCheckInProcessStartedEvent: () => {
        analytics.logEvent("check-in started");
    },
    logCheckInProcessCompletedEvent: (outcome) => {
        analytics.logEvent("check-in completed", { outcome });
    }
};

export default instance;