<template>
  <div @click="openModal" class="restart-wrapper">
    {{ text ? text : $t('restartBtnText') }}
  </div>
</template>

<script>
export default {
  name: "RestartBtn",
  props: ['text'],
  methods: {
    openModal() {
      this.$root.$emit('reset-modal', true)
    }
  }
}
</script>
