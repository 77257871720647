<template>
  <div class="navbar-wrapper">
    <div class="container position-relative d-flex align-items-sm-center justify-content-sm-between">
      <div class="d-flex nav-container">
        <img :style="$route.name == 'home' ? 'opacity:0': 'opacity:1'" class="back" @click="$router.go(-1)"
             src="../assets/img/header/back-copy.svg" alt="">
        <img @click="goTo('/')" src="../assets/img/nav-bar/bella-heart.svg" alt="">
        <div class="d-flex d-sm-none align-items-center flex-nowrap dots" @click="navAction(true, '#484f7f')">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="d-flex links">
        <router-link v-for="link in links"
                     :key="link.label"
                     :to="link.path"
                     class="d-block">{{ link.label }}
        </router-link>
      </div>
    </div>
    <div class="mobile-nav px-4"
         :class="{'nav-is-open':isNav}">
      <img width="24"
           @click="navAction(false, null)"
           class="close-icon"
           src="../assets/img/nav-bar/icon-20-close-light.svg" alt="">
      <div class="d-flex flex-column mt-5">
        <router-link v-for="link in links"
                     :key="link.label"
                     @click.native="changeColor"
                     :to="link.path"
                     class="mt-4">
          <img :src="link.icon" alt="">
          {{ link.label }}
        </router-link>
      </div>
      <div class="bottom">© {{ new Date().getFullYear() }} {{ $t('reserved') }}</div>
    </div>
  </div>
</template>

<script>
import metaTag from "../helper/meta-tag";

let icons = [
  require('../assets/img/nav-bar/icon-20-privacy.svg'),
  require('../assets/img/nav-bar/icon-20-terms.svg')
]
export default {
  name: "Navbar",
  data() {
    return {
      isNav: false,
      links: []
    }
  },
  created() {
    this.$t('navbar').forEach((el, i) => {
      el.icon = icons[i]
      this.links.push(el)
    })
  },
  methods: {
    changeColor() {
      this.navAction(false, '#d8e9ea')
    },
    goTo(path) {
      this.$router.push(path);
      this.changeColor();
    },
    navAction(state, color) {
      if (!color) {
        if (this.$route.name === 'positive') metaTag('#b2bdd4')
        else if (this.$route.name === 'negative') metaTag('#9dd8c7')
        else metaTag('#d8e9ea')
      } else metaTag(color)
      this.isNav = state;
    }
  },
}
</script>
