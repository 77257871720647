import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import i18n from "./i18n";
Vue.use(PerfectScrollbar)
import './assets/scss/app.scss'
import RestartBtn from "./components/RestartBtn";
import PageLayout from "./components/PageLayout";
import FirebaseService from "./services/firebase-service";
import AnalyticsService from "./services/analytics-service";

FirebaseService.init();
AnalyticsService.init();

Vue.component('RestartBtn', RestartBtn)
Vue.component('PageLayout', PageLayout)
// Make BootstrapVue available throughout your project
// Optionally install the BootstrapVue icon components plugin
Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
