<template>
  <div id="app" class="d-flex flex-column" :class="{'home-bg': $route.name === 'home'}">
    <Navbar/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

export default {
  components: {
    Navbar,
    Footer
  }
}
</script>
<style scoped lang="scss">
#app {
  background-image: url("assets/img/bg.jpg");
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  @media (min-width: 1919px) and (min-height: 768px) and (max-height: 1000px) {
    min-height: 110vh;
  }
  @media (max-width: 767px) {
    min-height: 100vh;
    &:after {
      background-image: url("assets/img/background-layout.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 0;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &.home-bg {
    @media (max-width: 767px) {
      background-image: url("assets/img/background-layout.jpg");
    }

    &:after {
      background-image: radial-gradient(circle at 50% 50%, rgba(226, 34, 101, 0.3), rgba(226, 34, 101, 0) 86%);
      opacity: .5;
    }
  }

  &.negative-bg {
    background-image: url("assets/img/background-layout.jpg");

    &:after {
      background-image: linear-gradient(61deg, rgba(52, 190, 149, 0) 50%, rgba(52, 190, 149, 0.5) 100%);
    }

    &:before {
      background-image: linear-gradient(304deg, rgba(52, 190, 149, 0) 50%, rgba(52, 190, 149, 0.5) 100%);
    }
  }

  &.positive-bg {
    background-image: url("assets/img/background-layout.jpg");

    &:after {
      background-image: linear-gradient(61deg, rgba(59, 72, 163, 0) 50%, rgba(59, 72, 163, 0.5) 100%);
    }

    &:before {
      background-image: linear-gradient(304deg, rgba(59, 72, 163, 0) 50%, rgba(59, 72, 163, 0.5) 100%);
    }
  }

}
</style>
