<template>
  <footer class="footer-wrapper d-flex align-items-center justify-content-center">
    © {{ new Date().getFullYear() }} {{ $t('reserved') }}
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
footer {
  font-size: 14px;
  color: #343c71;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 767px) {
    display: none !important;
  }
  @media (max-height: 838px) {
    position: static;
    transform: translateX(0);
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
