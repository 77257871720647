import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
    },
    {
        path: '/check-in/',
        name: 'check-in',
        component: () => import(/* webpackChunkName: "CheckIn" */ '../views/CheckIn.vue'),
        children: [
            {
                path: '1',
                name: '1',
                component: () => import(/* webpackChunkName: "Step_1" */ '../views/Step_1.vue')
            },
            {
                path: '2',
                name: '2',
                component: () => import(/* webpackChunkName: "Step_2" */ '../views/Step_2.vue')
            },
            {
                path: '3',
                name: '3',
                component: () => import(/* webpackChunkName: "Step_3" */ '../views/Step_3.vue')
            },
            {
                path: '4',
                name: '4',
                component: () => import(/* webpackChunkName: "Step_4" */ '../views/Step_4.vue')
            },
            {
                path: '5',
                name: '5',
                component: () => import(/* webpackChunkName: "Step_5" */ '../views/Step_5.vue')
            },
            {
                path: '6',
                name: '6',
                component: () => import(/* webpackChunkName: "Step_6" */ '../views/Step_6.vue')
            },
            {
                path: '7',
                name: '7',
                component: () => import(/* webpackChunkName: "Step_7" */ '../views/Step_7.vue')
            },
        ]
    },
    {
        path: '/negative',
        name: 'negative',
        component: () => import(/* webpackChunkName: "NegativeRes" */ '../views/NegativeRes.vue')
    },
    {
        path: '/positive',
        name: 'positive',
        component: () => import(/* webpackChunkName: "NegativeRes" */ '../views/PositiveRes.vue')
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import(/* webpackChunkName: "Policy" */ '../views/Policy.vue')
    },

    {
        path: '/terms',
        name: 'terms',
        component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
