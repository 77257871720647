<template>
  <div class="page-layout-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PageLayout"
}
</script>
